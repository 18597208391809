import {
  ApiResponse,
  IDonViResponseBase,
} from "@/core/interfaces/ApiResponses";
import { apiServiceInstance } from "@/core/helpers/utils";

export class DonViService {
  static fetchCurrentDepartmentById(departmentId: number): Promise<{
    data: ApiResponse<IDonViResponseBase>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/don-vi/chi-tiet/${departmentId}`
    );
  }

  static fetchAllDepartments(): Promise<{
    data: ApiResponse<IDonViResponseBase[]>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      "/api/v1/don-vi/danh-sach"
    );
  }
}
