import moment from 'moment';
import { useStore } from 'vuex';
import { computed } from 'vue';

export const useDateTime = () => {
  const store = useStore();

  const currentYear = computed(() => store.getters.getSelectedYear);

  const formatDisplayingDate = (date: Date | null, separatorChar = '-') => {
    if (!date) {
      return '-';
    }

    return moment(date).format(`DD${separatorChar}MM${separatorChar}YYYY`);
  }

  const formatDatePayload = (date: Date): string => {
    return moment(date).format(`YYYY-MM-DD`);
  }

  const disableDate = (time: Date, currentYear: number, forBaoCao = false) => {
    if (time.getFullYear() > currentYear) return true;

    if (time.getFullYear() < currentYear) return true;

    if (forBaoCao && time.getFullYear() === 2023 && time.getMonth() <= 5) return true;
  }

  const defaultDate = computed(() => {
    const now = new Date(currentYear.value);

    if (now.getMonth() <= 5 && +currentYear.value === 2023) return new Date(currentYear.value, 6);

    return now;
  });

  return {
    defaultDate,
    disableDate,
    moment,
    formatDisplayingDate,
    formatDatePayload,
  }
}
