import {useStore} from 'vuex';
import {computed} from 'vue';

export const useAccount = () => {
  const store = useStore();

  return {
    currentUserData: computed(() => store.getters.currentUser),
  }
}
