
import {defineComponent, PropType} from 'vue';
import {ButtonSizeEnums, ButtonsType, ButtonTypeColors, ButtonTypes} from '@/core/enums/buttons.enums';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import IconFaFileExportDoutone from '@/icons/IconFaFileExportThin.vue';
import IconSquareDoutone from "@/icons/IconSquareDoutone.vue";
import IconSquareLight from "@/icons/IconSquareLight.vue";
import IconSquareRegular from "@/icons/IconSquareRegular.vue";

export default defineComponent({
	name: 'button-custom',

	that: this,

	components: {IconSquareRegular, IconSquareLight, IconSquareDoutone, FontAwesomeIcon, IconFaFileExportDoutone},

	emits: ['click'],

	data() {
		return {
			disabled: false,
			timeOut: 0,
			ButtonsType,
		}
	},

	props: {
		buttonType: {
			type: String as PropType<ButtonsType>,
			default: ButtonsType.SAVE,
		},

		type: {
			type: String as PropType<ButtonTypes>,
			default: ButtonTypes.BUTTON,
		},

		colorType: {
			type: String as PropType<ButtonTypeColors>,
			default: ButtonTypeColors.PRIMARY,
		},

		classes: {
			type: String,
			default: '',
		},

		isDisabled: {
			type: Boolean,
			default: false,
		},

		isLoading: {
			type: Boolean,
			default: false,
		},

		immediate: {
			type: Boolean,
			default: true,
		},

		delayTime: {
			type: Number,
			default: 3000,
		},

    showIcon: {
      type: Boolean,
      default: true,
    },

		size: {
			type: String as PropType<ButtonSizeEnums>,
			default: ButtonSizeEnums.SMALL,
		}
	},

	computed: {
		classComputed() {
			return `btn-customize btn btn-${this.colorType} align-self-center me-1 my-1 ${this.classes} ${this.size}`.trim();
		},

		hasLabelSlot() {
			return !!this.$slots['button-label'];
		},

		solidIcon() {
			if ([
        ButtonsType.FA_FILE_EXPORT_THIN,
        ButtonsType.FA_SQUARE_DOUTONE,
        ButtonsType.FA_SQUARE_LIGHT,
        ButtonsType.FA_SQUARE_REGULAR,
      ].includes(this.buttonType)) return '';

			let buttonIcon;

			switch (this.buttonType) {
				case (ButtonsType.SAVE):
					buttonIcon = 'fa-solid fa-floppy-disk';
					break;
				case (ButtonsType.BACK):
					buttonIcon = 'fa-solid fa-arrow-left';
					break
				case (ButtonsType.FORWARD):
					buttonIcon = 'fa-solid fa-arrow-right';
					break;
				case (ButtonsType.TRASH):
					buttonIcon = 'fa-solid fa-trash';
					break;
				case (ButtonsType.ADD):
					buttonIcon = 'fa-solid fa-plus';
					break;
				case (ButtonsType.INFO):
					buttonIcon = 'fa-solid fa-circle-info';
					break;
				case (ButtonsType.EDIT):
					buttonIcon = 'fa-solid fa-pen-to-square';
					break;
				case (ButtonsType.REGULAR_QUESTION):
					buttonIcon = 'fa-regular fa-circle-question';
					break;
				case (ButtonsType.SOLID_TRIANGLE_EXCLAMATION):
					buttonIcon = 'fa-solid fa-triangle-exclamation';
					break;
				case (ButtonsType.SOLID_RIGHT_TO_BRACKET):
					buttonIcon = 'fa-solid fa-right-to-bracket';
					break;
        case (ButtonsType.SOLID_FILE_EXPORT):
          buttonIcon = 'fa-solid fa-file-export';
          break;
				case (ButtonsType.SOLID_FILE_IMPORT):
					buttonIcon = 'fa-solid fa-file-import';
					break;
				case (ButtonsType.MINUS):
					buttonIcon = 'fa-solid fa-minus';
					break;
				case (ButtonsType.FA_PRINT):
					buttonIcon = 'fa-solid fa-print';
					break;
				case (ButtonsType.FA_FILE_CIRCLE_PLUS):
					buttonIcon = 'fa-solid fa-file-circle-plus';
					break;
				case (ButtonsType.FA_CHECK_SQUARE):
					buttonIcon = 'fa-solid fa-square-check';
					break;
				case (ButtonsType.FA_SQUARE):
					buttonIcon = 'fa-solid fa-square';
					break;
				case (ButtonsType.FA_WRENCH):
					buttonIcon = `fa-solid fa-wrench`;
					break;
				case (ButtonsType.FA_FILE_CIRCLE_XMARK):
					buttonIcon = `fa-solid fa-file-circle-xmark`;
					break;
				case (ButtonsType.RECEIPT):
					buttonIcon = `fa-solid fa-receipt`;
					break;
				case (ButtonsType.FA_XMARK):
				default:
					buttonIcon = 'fa-solid fa-xmark';
			}

			return buttonIcon;
		},
	},

	methods: {
		click() {
			this.$emit('click')
		},

		onButtonClicked() {
			this.click();

			if (this.immediate) {
				return;
			}

			this.disabled = true;
			this.timeOut = setTimeout(() => {
				this.disabled = false;
			}, this.delayTime)
		}
	},

	beforeUnmount () {
		clearTimeout(this.timeOut)
	}
})
