
import {defineComponent} from 'vue';
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {useAccount} from '@/core/hooks/use-account';
import {swalNotification} from '@/core/helpers/utils';
import {DonViService} from '@/core/services/DonVi.service';
import ChucVuService from '@/core/services/ChucVu.service';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {useDateTime} from '@/core/hooks/use-date-time-hook';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';

export default defineComponent({
	name: 'chi-tiet-nguoi-dung',

	components: { PageRowLayout, ButtonCustom },

	setup() {
		const { push, goBack } = useRouterCustom();
		const { currentUserData } = useAccount();
		const { moment } = useDateTime();

		return {
			push,
			goBack,
			user: currentUserData,
			moment,
		}
	},

	data() {
		return {
			tenDonVi: '',
			chucVu: '',
		}
	},

	async mounted() {
		setCurrentPageTitle('Tài khoản');

		try {
			const { data: { data: { ten_don_vi } } } = await DonViService.fetchCurrentDepartmentById(
				this.user.departmentId
			);
			this.tenDonVi = ten_don_vi;
		} catch {
			await swalNotification(
				'Có lỗi xảy ra khi lấy thông tin đơn vị của người dùng này',
				'error',
			);
		}

		try {
			const { data: { data: { ten_chuc_vu } } } = await ChucVuService.get(this.user.positionId);
			this.chucVu = ten_chuc_vu;
		} catch {
			await swalNotification(
				'Có lỗi xảy ra khi lấy thông tin chức vụ của người dùng này',
				'error',
			);
		}
	}
})
