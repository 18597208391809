export enum ButtonsType {
  ADD = 'ADD',
  BACK = 'BACK',
  CANCEL = 'CANCEL',
  EDIT = 'EDIT',
  FA_FILE_EXPORT_THIN = 'FA_FILE_EXPORT_THIN',
  FA_SQUARE_DOUTONE = 'FA_SQUARE_DOUTONE',
  FA_SQUARE_LIGHT = 'FA_SQUARE_LIGHT',
  FA_SQUARE_REGULAR = 'FA_SQUARE_REGULAR',
  FA_PRINT = 'FA_PRINT',
  FA_FILE_CIRCLE_PLUS = 'FA_FILE_CIRCLE_PLUS',
  FA_CHECK_SQUARE = 'FA_CHECK_SQUARE',
  FA_SQUARE = 'FA_SQUARE',
  FA_WRENCH = 'FA_WRENCH',
  FA_FILE_CIRCLE_XMARK = 'FA_FILE_CIRCLE_XMARK',
  FA_XMARK = 'FA_XMARK',
  FORWARD = 'FORWARD',
  INFO = 'INFO',
  MINUS = 'MINUS',
  RECEIPT = 'RECEIPT',
  REGULAR_QUESTION = 'REGULAR_QUESTION',
  SAVE = 'SAVE',
  SOLID_TRIANGLE_EXCLAMATION = 'SOLID_TRIANGLE_EXCLAMATION',
  SOLID_RIGHT_TO_BRACKET = 'SOLID_RIGHT_TO_BRACKET',
  SOLID_FILE_EXPORT = 'SOLID_FILE_EXPORT',
  SOLID_FILE_IMPORT = 'SOLID_FILE_IMPORT',
  TRASH = 'TRASH',
}

export enum ButtonTypes {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset'
}

export enum ButtonTypeColors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  DARK = 'dark',
  LINK = 'link',
  LIGHT = 'light'
}

export enum ButtonSizeEnums {
  SMALL = 'btn-sm',
  LARGE = 'btn-lg',
}
