import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = {
  key: 2,
  class: "ms-2 break-word"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconFaFileExportDoutone = _resolveComponent("IconFaFileExportDoutone")!
  const _component_IconSquareDoutone = _resolveComponent("IconSquareDoutone")!
  const _component_IconSquareLight = _resolveComponent("IconSquareLight")!
  const _component_IconSquareRegular = _resolveComponent("IconSquareRegular")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.type,
    disabled: _ctx.isDisabled || _ctx.isLoading || _ctx.disabled,
    class: _normalizeClass(_ctx.classComputed),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onButtonClicked && _ctx.onButtonClicked(...args)))
  }, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.showIcon)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (!_ctx.solidIcon)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_ctx.buttonType === _ctx.ButtonsType.FA_FILE_EXPORT_THIN)
                        ? (_openBlock(), _createBlock(_component_IconFaFileExportDoutone, { key: 0 }))
                        : _createCommentVNode("", true),
                      (_ctx.buttonType === _ctx.ButtonsType.FA_SQUARE_DOUTONE)
                        ? (_openBlock(), _createBlock(_component_IconSquareDoutone, { key: 1 }))
                        : _createCommentVNode("", true),
                      (_ctx.buttonType === _ctx.ButtonsType.FA_SQUARE_LIGHT)
                        ? (_openBlock(), _createBlock(_component_IconSquareLight, { key: 2 }))
                        : _createCommentVNode("", true),
                      (_ctx.buttonType === _ctx.ButtonsType.FA_SQUARE_REGULAR)
                        ? (_openBlock(), _createBlock(_component_IconSquareRegular, { key: 3 }))
                        : _createCommentVNode("", true)
                    ], 64))
                  : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 1,
                      icon: _ctx.solidIcon
                    }, null, 8, ["icon"]))
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          icon: "fas fa-circle-notch fa-spin"
        })),
    (_ctx.hasLabelSlot)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "button-label")
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}