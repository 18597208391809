import { apiServiceInstance } from "../helpers/utils";
import {
  ApiResponse,
  PositionBaseResponse,
} from "./../interfaces/ApiResponses";

export default class ChucVuService {
  static list(): Promise<{
    data: ApiResponse<{
      data: PositionBaseResponse[];
      current_page: number;
      first_page_url: string;
      from: number;
      last_page: number;
      last_page_url: string;
      next_page_url: string | null;
      per_page: number;
      prev_page_url: string | null;
      to: number;
      total: number;
    }>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      "/api/v1/chuc-vu/danh-sach"
    );
  }

  static get(id: number): Promise<{
    data: ApiResponse<PositionBaseResponse>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/chuc-vu/chi-tiet/${id}`
    );
  }

  static create(
    name: string,
    shortName: string
  ): Promise<{
    data: ApiResponse<PositionBaseResponse>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("chucVuInstance[ten_chuc_vu]", name);
    bodyFormData.append("chucVuInstance[ten_viet_tat]", shortName);
    return apiServiceInstance().vueInstance.axios.post(
      "/api/v1/chuc-vu/tao-moi",
      bodyFormData
    );
  }

  static update(
    id: number,
    name: string,
    shortName: string
  ): Promise<{
    data: ApiResponse<PositionBaseResponse>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("chucVuInstance[ten_chuc_vu]", name);
    bodyFormData.append("chucVuInstance[ten_viet_tat]", shortName);
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/chuc-vu/cap-nhat/${id}`,
      bodyFormData
    );
  }

  static delete(id: number) {
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/chuc-vu/xoa/${id}`
    );
  }
}
