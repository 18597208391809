import {useRoute, useRouter} from 'vue-router';
import {onMounted, ref} from 'vue';

export const useRouterCustom = (idString = 'id') => {
  const router = useRouter();
  const route = useRoute();
  const id = ref(0);

  const goBack = () => {
    router.go(-1);
  }

  const replace = async (path) => {
    await router.replace({ path });
  }

  const push = async (path) => {
    await router.push({ path });
  }

  onMounted(() => {
    id.value = +route.params[idString];
  });

  const searchInUri = (term: string) => {
    const { fullPath } = route;
    return fullPath.includes(term);
  }

  return {
    goBack,
    replace,
    push,
    id,
    searchInUri,
  }
}
